import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
// import '../theme/index.css'; 
import formCreate from '@form-create/element-ui'  
// import 'element-ui/lib/theme-chalk/index.css';


import SwDesigner from '@/components/SwDesigner/index';
import '../element-variables.scss'


Vue.use(ElementUI); 
Vue.use(formCreate);
Vue.component('SwDesigner', SwDesigner);
 
Vue.config.productionTip = false 

new Vue({
  router,
  render: h => h(App) 
}).$mount('#app')
 