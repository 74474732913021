const label = '布局模板1';
const name = 'layout1';

export default {
    icon: 'icon-row',
    label,
    name,
    mask: false,
    rule() {
        return {
            type: 'el-container',
            props:{
                class:"el-container is-vertical"
            },
            children: []
        };
    },
    children: "header,main,footer",
    props() {
        return [];
    }
};
