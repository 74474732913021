<template>
  <el-aside class="_fc-l" width="232px">
    <el-collapse v-model="activeNames" @change="handleChange" :accordion="true">
      <el-collapse-item
        :key="index"
        :title="item.title"
        :name="index"
        v-for="(item, index) in menuList"
      >
        <div class="_fc-l-group" :key="index">
          <draggable
            :group="{ name: 'default', pull: 'clone', put: false }"
            :sort="false"
            :list="item.list"
          >
            <div
              class="_fc-l-item"
              v-for="(data, index) in item.list"
              :key="index"
            >
              <div class="_fc-l-icon">
                <i class="fc-icon" :class="data.icon || 'icon-input'"></i>
              </div>
              <span class="_fc-l-name">{{ data.label }}</span>
            </div>
          </draggable>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-aside>
</template>
<script>
import draggable from "vuedraggable";
import createMenu from "@/components/SwDesigner/config/menu";
export default {
  name: "leftComponentsMenuNav",
  components: {
    draggable,
  },
  data() {
    return {
      menuList: this.menu || createMenu(),
      activeNames: 0,
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>
<style scoped>
._fc-l /deep/ .el-collapse-item__header {
  width: 90%;
  margin: 10px auto;
  font-size: 14px;
  cursor: pointer;
}
._fc-l /deep/ .el-collapse-item__header.is-active {
  color: #ffffff;
}
</style>
