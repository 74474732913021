const name = 'main';

export default {
    name,
    drag: true,
    dragBtn: false,
    inside: true,
    mask: false,
    rule() {
        return {
            type: "el-main",
            children: []
        };
    },
    props() {
        return [];
    }
};
