const name = "iframe";
const label = "iframe";

export default {
    name,
    label,
    icon:"icon-editor",
    rule(){
        return {
            type:"iframe",
            name: "pageiframe",
            props:{
                name:"pageiframe",
                style:"border:none;width:100%",
                height:"500",
            }

        }
    },
    props(){
        return [{
            title:"高度",
            type:"input",
            field:"height",
            value:500
        }]
    }
}