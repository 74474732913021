const name = 'header';
const label = '页头';

export default {
    icon:"icon-row",
    name,
    label,
    mask: true,
    rule() {
        return {
            type: "header",
            props:{
                class:"swheader"
            },
            children:[{
                type:"div",
                title:"",
                orgData:"页面名称",
                props:{
                    class:"sw-header-title"
                },
                children:["页面名称"]
            },{
                type:"div",
                props:{
                    class:"sw-user"
                },
                children:[{
                    type:"img",
                    props:{
                        src:"https://i-1.lanrentuku.com/2020/12/17/1719f25f-1600-4249-ae86-41b7ba11deaa.jpg?imageView2/2/w/500/",
                        width:"42",
                        style:"border-radius:30px; margin-top:10px"
                    }
                }]
            }]
        };
    },
    props() {
        return [{
            type: "el-button",
            props: {
                size: "mini"
            },
            on: {
                click: function () {
                    window._designer.showHeaderEdit = true;
                }
            },
            children: ["编辑菜单内容"]
        },];
    }
};
