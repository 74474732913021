const label = '布局模板2';
const name = 'layout2';

import header from './header';
import container2 from './container2'
import footer from './footer';

export default {
    icon: 'icon-row',
    label,
    name,
    mask: false,
    rule() {
        return {
            type: 'el-container',
            props:{
                class:"el-container is-vertical"
            },
            children: []
        };
    },
    children: [header, container2, footer],
    props() {
        return [];
    }
};
