import axios from "axios";
import { MessageBox, Message } from "element-ui";
import qs from 'qs';
import store from "@/store";
// create an axios instance
console.log('in http', process.env.VUE_APP_BASE_URL);
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000, // request timeout 
});
// request interceptor
http.interceptors.request.use(
  (config) => {
    // do something before request is sent

    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation

    // //   config.headers['X-Token'] = getToken()
    // }

    config.headers['Content-Type'] = "application/x-www-form-urlencoded"

    //处理请求数据
    if(config.method === 'post'){
        //将请求参数进行转换，这里是全局配置post请求参数
          config.data =  qs.stringify({ouiData:JSON.stringify(config.data)})
    }
    
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
http.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.success !== true) {
      Message({
        message: res.msg || "Error",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default http;
