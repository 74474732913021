const label = '导航菜单';
const name = 'menu';

import { createMenusJson, getUrlParam } from "../../utils"



export default {
    icon: 'icon-row',
    label,
    name,
    mask: false,
    rule() {
        return {
            type: 'el-menu',
            props: {
                class: "el-menu-vertical-demo"
            },
            effect: {
                fetch: {
                    action: process.env.VUE_APP_BASE_URL + window.startWe.portal.extraAttrs.loadMenuDesignUrl + `&portalId=${getUrlParam('portalId')}`,
                    method:'GET', 
                    parse(res, currentRule){
                        let menus = [];
                        if( res.menus.length ){
                            menus = res.menus[0].children.map( menu => {
                                return createMenusJson( menu );
                            })
                            currentRule.orgData = res.menus[0].children;
                            currentRule.children = menus;
                        }
                    }
                }
            },
            orgData: {},
            children: []
        }
    },
    props() {
        return [{
            type: "el-button",
            props: {
                size: "mini"
            },
            on: {
                click: function () {
                    window._designer.showMenusDrawer = true;
                }
            },
            children: ["编辑菜单内容"]
        },];
    }
};