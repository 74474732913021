import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DesignLayout from '../pages/design/layout.vue'
import { getToken ,setToken ,removeToken } from '@/utils/auth';
Vue.use(VueRouter)




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/design',
    name: 'design', 
    component:DesignLayout,
    children:[
      {
        path:'portal',
        name:'portal',
        component: () => import(/* webpackChunkName: "about" */ '../pages/design/portal/index.vue')
      }
    ],
   
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})




 

router.beforeEach(async(to, from, next) => {
  // start progress bar 

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) { 
    //清空cookies
    removeToken() 
  }  

  const comeToken = to.query.token 
    if(to.query.token && !hasToken){ 
       setToken(comeToken)
    }

  next()
})




export default router
