const name = "container2";

import aside from "./aside";
import main from "./main";

aside._newRule = {
    props:{
        width:"200px"
    }
}

export default {
    name,
    mask: false,
    rule(){
        return {
            type:"el-container",
            children:[]
        }
    },
    children: [aside, main]
}