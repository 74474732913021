import Cookies from 'js-cookie'

const TokenKey = 'swtoken' 

export function getToken() {
    
  return Cookies.get(TokenKey)
}

export function setToken(token) { 
   const tokenobj = token.split("__") 
    Cookies.set("tokenId", tokenobj[0])
    Cookies.set("userId", tokenobj[1]) 
    return Cookies.set(TokenKey, token)
}

export function removeToken() { 
  return Cookies.remove(TokenKey)
}