import { render, staticRenderFns } from "./TableOptions.vue?vue&type=template&id=3f510e13&scoped=true&"
import script from "./TableOptions.vue?vue&type=script&lang=js&"
export * from "./TableOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f510e13",
  null
  
)

export default component.exports