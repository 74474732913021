const name = 'footer';
const label = '页脚';

export default {
    icon:"icon-row",
    name,
    label,
    mask: false,
    rule() {
        return {
            type: "el-footer",
            props:{
                class:"swfooter"
            },
            children: ["@版权所有-2022-10-11"]
        };
    },
    props() {
        return [{
            title:"页脚内容",
            type:"input",
            field:"formCreateChild",
        }];
    }
};
