<template>
  <div class="home">
    <nav>
      <router-link to="/">首页</router-link> |
      <router-link to="/design/portal">门户</router-link>
    </nav>
  </div>
</template>

<script>
import http from "@/utils/http";

export default {
  name: "HomeView",
  components: {},
  mounted() {
    http({
      url:
        "FZFbIn.biz?projectId=629585360082436096&o_as=952f36d9372ac55b02ff25db8e4385483fb1074d",
      method: "post",
      data: {
        a: 1223,
      },
    }).then((res) => {
      console.info(res);
    });
  },
};
</script>
